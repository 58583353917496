import React from 'react';
import { Link } from 'react-router-dom';
import upwork from '../../images/upwork_icon.svg';
import instagram from '../../images/instagramnew.svg';
import mail from '../../images/mail.svg';

const Navigation = (props) => {

  let navBurgerClasses = 'burger_icon';
  let nav_container = 'nav_container';
  let background = 'background';
  if (props.navState) {
    navBurgerClasses = "burger_icon burger_icon_open";
    nav_container = "nav_container nav_container_open";
    background = "background background_open";
  }


  return (
    <div id="navigation_item">
      <div onClick={props.toogleNav} className={background}></div>
      <div className={nav_container}>
        <div className="circle_contact_holder" >
          <a href="mailto:office@circlestudiobg.com?Subject=Services%inquiry">office@circlestudiobg.com</a>
          <a target="_blank" href="https://www.instagram.com/circle.studio.bg/">instagram</a>
        </div>
        <div className="nav_links">
          <Link className="nav_a" to="/theteam" onClick={props.toogleNav}> The Team</Link>
          <Link className="nav_a" to="/projects" onClick={props.toogleNav}>PROJECTS</Link>
          <Link className="nav_a" to="/gallery" onClick={props.toogleNav}>Services</Link>
        </div>
        <div className="contact_par_navigation">
            <p>CONTACT US</p>
            <div className="navigation_contact_items">
              <a href="#"><img src={mail} className="footer_logo" alt="UnderConstructionCity" /></a>
              <a href="#"><img src={instagram} className="footer_logo" alt="UnderConstructionCity" /></a>
              <a href="#"><img src={upwork} className="footer_logo" alt="UnderConstructionCity" /></a>
            </div>
        </div>
      </div>

      <div className={navBurgerClasses} onClick={props.toogleNav}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Navigation;
