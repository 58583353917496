import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import srdjansusa from '../../images/teamImages/srdjanSusa.jpg';
import dusanmuncan from '../../images/teamImages/dusanMuncan.jpg';
import ilijademenesku from '../../images/teamImages/ilijaDemenesku.jpg';

class TheTeam extends Component {

    render() {
      return (
          <div className="articles_wrapper">
            <h3 className="segment_title">THE TEAM</h3>
              <p className="team_description">Cupcake ipsum dolor sit amet jelly brownie. Jelly beans chupa chups croissant powder. Jujubes I love gummies soufflé dessert.</p>
              <p className="team_description">Donut sugar plum tart. Brownie chocolate bar danish wafer. Marzipan liquorice cake chocolate bar liquorice croissant cake sugar plum I love. Donut sugar plum tart. Brownie chocolate bar danish wafer. Marzipan liquorice cake chocolate bar liquorice croissant cake sugar plum I love. Donut sugar plum tart. Brownie chocolate bar danish wafer. Marzipan liquorice cake chocolate bar liquorice croissant cake sugar plum I love. Brownie chocolate bar danish wafer. Marzipan liquorice cake chocolate bar liquorice croissant cake sugar plum I love. Donut sugar plum tart. Brownie chocolate bar danish wafer. Marzipan liquorice cake chocolate bar liquorice croissant cake sugar plum I love.</p>
            <div className="theteam_people">
              <div className="sigle_team_member">
                <img src={srdjansusa} alt="tem_member_image" />
                <h3>Srdjan Susa</h3>
                <h4>Architect</h4>
                <h6>project & delivery managment, communications</h6>
                <p></p>
              </div>
              <div className="sigle_team_member">
                <img src={dusanmuncan} alt="tem_member_image" />
                <h3>Dusan Muncan</h3>
                <h4>Architect</h4>
                <h6>architectural design & functio n</h6>
                <p></p>
              </div>
              <div className="sigle_team_member">
                <img src={ilijademenesku} alt="tem_member_image" />
                <h3>Ilija Demenesku</h3>
                <h4>Architect</h4>
                <h6>3d modeling & visualization</h6>
                <p></p>
              </div>
            </div>
          </div>
      )
    }
}

export default TheTeam;
