import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = (props) => {

  return (
    <div className="thankyou">
      <h3>THANK YOU FOR SUBSCRIBING!</h3>
      <p>We promise to deliver only high quality content to your email.<br/>Thank you once again for your trust.</p>
      <Link to="/#" onClick={props.toogleNav}>Back to the site</Link>
    </div>
  )
}

export default ThankYou;
