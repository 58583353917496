import React, { Component } from 'react';
import ProjectList from './ProjectList/ProjectList';
import SliderComponent from './SliderComponent/SliderComponent';
class Portfolio extends Component {
  componentDidMount() {
    //hides footer when component mounts
    document.getElementById('footer_element').classList.add('hide_element');
    window.addEventListener('scroll', this.handleScroll);
    let heightCounter = document.getElementsByClassName('js_event_height');
    this.setState({
      heightOfItem: heightCounter[0].offsetHeight,
    });
  }

  componentWillUnmount() {
    //shows footer when component unmounts
    document.getElementById('footer_element').classList.remove('hide_element');
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.handleProjectArrowDisable(this.state.currentItemGallery);
  }

  state = {
    idEventItem: 0,
    currentItemGallery: 0,
    filteredEventList: [
      {
        name: 'BELGRADE METRO',
        surface: '44',
        images: [
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_01.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_02.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_03.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_04.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_05.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_06.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_07.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_08.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_09.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_10.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_11.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_12.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_13.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_14.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_15.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_16.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_17.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_18.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_19.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_20.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_21.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_22.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_23.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_24.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_25.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_26.jpg',
          'http://circlestudiobg.com/circleStudioImages/bgd_metro/BGD_METRO_27.jpg'
        ],
      },
      {
        name: 'BRUSSELS APT',
        surface: '44',
        images: [
          'http://circlestudiobg.com/circleStudioImages/Belgija/B01.jpg',
          'http://circlestudiobg.com/circleStudioImages/Belgija/B02.jpg',
          'http://circlestudiobg.com/circleStudioImages/Belgija/B03.jpg',
          'http://circlestudiobg.com/circleStudioImages/Belgija/B04.jpg',
          'http://circlestudiobg.com/circleStudioImages/Belgija/B05.jpg'
        ],
      },
      {
        name: 'ANGULO GASTRO BAR',
        surface: '44',
        images: [
          'http://circlestudiobg.com/circleStudioImages/angulo/Angulo01.jpg',
          'http://circlestudiobg.com/circleStudioImages/angulo/Angulo02.jpg',
          'http://circlestudiobg.com/circleStudioImages/angulo/Angulo03.jpg',
          'http://circlestudiobg.com/circleStudioImages/angulo/Angulo04.jpg',
          'http://circlestudiobg.com/circleStudioImages/angulo/Angulo05.jpg',
          'http://circlestudiobg.com/circleStudioImages/angulo/Angulo06.jpg'
        ],
      },
      {
        name: 'WEST 65 APT',
        surface: '44',
        images: [
          'http://circlestudiobg.com/circleStudioImages/west65/2.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/02.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/3.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/4.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/5.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/6.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/7.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/8.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/9.jpg',
          'http://circlestudiobg.com/circleStudioImages/west65/10.jpg',
        ],
      },
      {
        name: 'KOSANCIC APT',
        surface: '56',
        images: [
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic1.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic2.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic2-2.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic3.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic4.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic5.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic5-5.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic5-6.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic6.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic6-1.jpg',
          'http://circlestudiobg.com/circleStudioImages/kosancic/kosancic7.jpg',
        ],
      },
      {
        name: 'PALILULA APT',
        surface: '44',
        images: [
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska1.jpg',
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska2.jpg',
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska3.jpg',
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska4.jpg',
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska5.jpg',
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska6.jpg',
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska7.jpg',
          'http://circlestudiobg.com/circleStudioImages/sajkaska/sajkaska8.jpg',
        ],
      },
      {
        name: 'MINI HOME',
        surface: '44',
        images: [
          'http://circlestudiobg.com/circleStudioImages/minihome/01.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/1.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/2.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/3.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/4.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/5.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/6.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/7.jpg',
          'http://circlestudiobg.com/circleStudioImages/minihome/8.jpg',
        ],
      }
    ],
    heightOfItem: 0,
  };

  handleProjectClick = (e) => {
    let selectedProject = e.target.id;
    let selectedInt = selectedProject.replace(/\D/g, '');
    this.setState((prevState) => ({
      currentItemGallery: parseInt(selectedInt),
    }));
    let gallery = document.getElementById('gallery_holder');
    gallery.classList.add('gallery_holder_open');
  };

  handleProjectSwitch = (eventID) => {
    if (eventID === 'narrow') {
      if (
        this.state.currentItemGallery > -1 &&
        this.state.currentItemGallery < this.state.filteredEventList.length - 1
      ) {
        this.setState((prevState) => ({
          currentItemGallery: parseInt(prevState.currentItemGallery + 1),
        }));
      }
    } else if (eventID === 'parrow') {
      if (
        this.state.currentItemGallery > 0 &&
        this.state.currentItemGallery < this.state.filteredEventList.length
      ) {
        this.setState((prevState) => ({
          currentItemGallery: parseInt(prevState.currentItemGallery - 1),
        }));
      }
    }
  };

  handleProjectArrowDisable = (numberItemSelected) => {
    let nextElem = document.getElementById('narrow');
    let previousElem = document.getElementById('parrow');

    nextElem.classList.remove('arrow_disabled');
    previousElem.classList.remove('arrow_disabled');

    if (numberItemSelected == 0) {
      previousElem.classList.add('arrow_disabled');
    }
    if (numberItemSelected == this.state.filteredEventList.length - 1) {
      nextElem.classList.add('arrow_disabled');
    }
  };

  render() {
    return (
      <div className='cercle_container'>
        <h3 className='segment_title'>CIRCLE STUDIO</h3>
        <div id='gallery_holder' className='gallery_holder'>
          <SliderComponent
            gallerylist={this.state.filteredEventList}
            galleryElement={this.state.currentItemGallery}
            pswitch={this.handleProjectSwitch}
          />
        </div>
        <div className='events_list'>
          <ProjectList
            base={this.state.filteredEventList}
            clickProject={this.handleProjectClick}
          />
        </div>
      </div>
    );
  }
}

export default Portfolio;
