import React from 'react';
import Aux from '../../../hoc/Aux';


const ProjectList = (props) => {

  return (
    <Aux>
        {props.base.map((projectItem, index) => (
          <div onClick={e => props.clickProject(e)} key={index} className="single_event js_event_height">
            <div className="stickey_heder">
              <img id={index + 'singleeventimg'} src={projectItem.images[0]} />
              <h4 id={index + 'singlhc'}>{projectItem.name}</h4>
            </div>
          </div>
            )
          )
        }
    </Aux>
  )
}

export default ProjectList;
