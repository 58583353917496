import React, { useState } from 'react';

const SliderComponent = (props) => {

  var gallerySliders = document.getElementsByClassName("images_wrapper");
  var buildingHeadlines = document.getElementsByClassName("slider_buildings_selector");

  var text1 = document.getElementById("text1");
  var text2 = document.getElementById("text2");
  var text3 = document.getElementById("text3");

  var infoScreen = document.getElementById("info_screen");
  var opacityToggleSlider = document.getElementById("opacityToggleSlider");

  function handleClick(e) {
    console.log(text1);
      for (var i = 0; i < buildingHeadlines.length; i++) {
        buildingHeadlines.item(i).classList.remove('slider_buildings_selector_active')
      }
    e.target.classList.add('slider_buildings_selector_active');
    if (e.target.id == 'politika'  ){
      document.getElementById("text1").classList.add('single_building_slider_description_active');
      document.getElementById("text2").classList.remove('single_building_slider_description_active');
      document.getElementById("text3").classList.remove('single_building_slider_description_active');
      for (var i = 0; i < gallerySliders.length; i++) {
        gallerySliders.item(i).style.marginLeft = "0";
      }
    }
    if (e.target.id == 'genex'  ){
      document.getElementById("text1").classList.remove('single_building_slider_description_active');
      document.getElementById("text2").classList.add('single_building_slider_description_active');
      document.getElementById("text3").classList.remove('single_building_slider_description_active');
      for (var i = 0; i < gallerySliders.length; i++) {
        gallerySliders.item(i).style.marginLeft = "-100%";
      }
    }
    if (e.target.id == 'beogradjanka'  ){
      document.getElementById("text1").classList.remove('single_building_slider_description_active');
      document.getElementById("text2").classList.remove('single_building_slider_description_active');
      document.getElementById("text3").classList.add('single_building_slider_description_active');
      for (var i = 0; i < gallerySliders.length; i++) {
        gallerySliders.item(i).style.marginLeft = "-200%";
      }
    }
  }
  function handleMobileSwitchGallery(e) {
    document.getElementById("info_screen").style.opacity = "0";
    document.getElementById("opacityToggleSlider").classList.toggle('opacity_switch')
  }
  return (
    <div>

    <h4 className="headline_float_right">DRAWINGS VS REALITY</h4>
    <p className="description_small">
      Checkout elevation drawings next to building photos. Sometimes the reality can seem disparate from the elvations you see. Is it because of neglect, position of the viewer, urban context or something else? Let us know what did you see here.<span></span>
    </p>
    <div className="tripiece_gallery_wrapper">
      <div className="tripiece_gallery_sliders">
        <div onClick={handleMobileSwitchGallery.bind(this)} className="mobile_switch_info">
          <div id="info_screen" className="mobile_switch_info_text">
            <p>Click on image to switch between dwg and jpg</p>
          </div>
        </div>
        <div className="tripiece_image_slider">
          <div className="images_wrapper">
            <img src="http://underconstructioncity.com/imgs/politika_dwg.jpg" alt="politika_dwg"/>
            <img src="http://underconstructioncity.com/imgs/genex_dwg.jpg" alt="genex_dwg"/>
            <img src="http://underconstructioncity.com/imgs/beogradjanka_dwg.jpg" alt="beogradjanka_dwg"/>
          </div>
        </div>
        <div id="opacityToggleSlider" className="tripiece_image_slider">
          <div className="images_wrapper">
            <img src="http://underconstructioncity.com/imgs/politika_photo.jpg" alt="politika_photo"/>
            <img src="http://underconstructioncity.com/imgs/genex_photo.jpg" alt="genex_photo"/>
            <img src="http://underconstructioncity.com/imgs/beogradjanka_photo.jpg" alt="beogradjanka_photo"/>
          </div>
        </div>
      </div>
      <div className="gallery_controler">
        <div className="slider_buildings_selector_holder">
          <p id="politika" onClick={(e) => handleClick(e)} className="slider_buildings_selector slider_buildings_selector_active">Politika</p>
          <p id="genex" onClick={(e) => handleClick(e)} className="slider_buildings_selector">Genex</p>
          <p id="beogradjanka" onClick={(e) => handleClick(e)} className="slider_buildings_selector">Beogradjanka</p>
        </div>
        <div className="slider_buildings_selector_description_holder">
          <div className="slider_buildings_selector_description_wrapper">
            <div id="text1" className="single_building_slider_description single_building_slider_description_active">
            <p>Uglješa Bogunović and Slobodan Janjić</p>
            <p>1967.</p>
            <p>Belgrade, Serbia</p>
            </div>
            <div id="text2" className="single_building_slider_description">
            <p>Mihajlo Mitrović</p>
            <p>1980.</p>
            <p>Belgrade, Serbia</p>
            </div>
            <div id="text3" className="single_building_slider_description">
            <p>Branko Pešić</p>
            <p>1974.</p>
            <p>Belgrade, Serbia</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    </div>
  )
}

export default SliderComponent;
