import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import imageforhome from '../../images/PortfolioImages/kosancic2.JPG';
import Aux from '../../hoc/Aux';

class homePage extends Component {

    render() {
      return (
        <Aux>
          <h3 className="segment_title">CIRCLE STUDIO</h3>
          <div className="home_segment">
            <img src={imageforhome} />
          </div>
        </Aux>
      )
    }
}

export default homePage;
