import React, { Component } from 'react';
import { Route, HashRouter } from 'react-router-dom';
import logo from './images/logo.svg';
import './App.scss';
import Navigation from './Components/Navigation/Navigation';
import Portfolio from './Components/Portfolio/Portfolio';
import TheTeam from './Components/TheTeam/TheTeam';
import Gallery from './Components/Gallery/Gallery';
import Footer from './Components/Footer/Footer';
import ThankYou from './Components/ThankYou/ThankYou';
import HomePage from './Components/HomePage/HomePage';

class App extends Component {

  state = {
    navDrawerOpen: false,
    contact: false,
    arrowHoverLeft: false,
    arrowHoverRight: false,
    galleryContainer: false,
    artPrintToggle: false
  };

  toggleNavClickHandler = () => {
    this.setState((prevState) => {
      return {navDrawerOpen: !prevState.navDrawerOpen};
    });
    this.setState((prevState) => {
      if(this.state.contact === true){
        return {contact: !prevState.contact};
      }
    });
  };

  toggleContactClickHandler = () => {
    this.setState((prevState) => {
      return {contact: !prevState.contact};
    });
  };

  togglekGalleryConatinerHandler = () => {
    this.setState((prevState) => {
      return {galleryContainer: !prevState.galleryContainer};
    });
  };


  handleLogoClick = () => {
    let gallery = document.getElementById('gallery_holder');
    gallery.classList.remove('gallery_holder_open');
  }


  render() {
    return (
      <HashRouter>
        <div>
        <Route path="/" exact render={(routeProps) => (
            <Portfolio />
            )} />
          <Route path="/theteam" exact render={(routeProps) => (<TheTeam {...routeProps}
            />)} />
          <Route path="/gallery" exact render={(routeProps) => (<Gallery {...routeProps}
                                                              galleryContainer={this.state.galleryContainer}
                                                              galleryContainerOpen={this.togglekGalleryConatinerHandler}
                                                            />)} />
          <Route path="/thankYou" exact render={(routeProps) => (<ThankYou {...routeProps}
                                                            />)} />
          <Navigation
            toogleNav={this.toggleNavClickHandler}
            navState={this.state.navDrawerOpen}
            contact={this.toggleContactClickHandler}
            contactState={this.state.contact}
          />
        <a onClick={this.handleLogoClick} className="home_logo" href="#"><img src={logo} className="home_logo" alt="homelogo" /></a>
        </div>
        <Footer />
      </HashRouter>
    );
  }
}

export default App;
