import React, { useState, Component } from 'react';
import Aux from '../../../hoc/Aux';
import projectimage from '../../../images/PortfolioImages/kosancic1.JPG';


class SliderComponent extends Component {

  componentDidMount() {
    this.handleDotSelection(0);
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    let galleryWrapper = document.getElementById('all_img_holder');
    galleryWrapper.style.marginLeft = - 100 * (this.state.idNo) + 'vw';
    this.handleDotSelection(this.state.idNo);
  }

  state = {
    idNo : 0
  }

  handleDotSelection = ( itemNumber ) => {
    let elementDots = document.getElementsByClassName("js-dots");
    let nextElem = document.getElementById("next");
    let previousElem = document.getElementById("previous");
    let i;
    for (i = 0; i < elementDots.length; i++) {
      elementDots[i].classList.remove("img_gallery_selected")
    }
    elementDots[itemNumber].classList.add("img_gallery_selected");

    nextElem.classList.remove("arrow_disabled");
    previousElem.classList.remove("arrow_disabled");

    if (this.state.idNo == 0) {
      previousElem.classList.add("arrow_disabled")
    }
    if (this.state.idNo == elementDots.length - 1) {
      nextElem.classList.add("arrow_disabled")
    }

  }


  handleImageChange = ( eventID ) => {
    if (eventID === 'previous') {
      this.setState(prevState => ({
        idNo: parseInt(prevState.idNo - 1)
      }));
    } else if (eventID === 'next') {
      this.setState(prevState => ({
        idNo: parseInt(prevState.idNo + 1)
      }));
    } else {
        this.setState(prevState => ({
          idNo: parseInt(eventID)
        }));
    }
  }

  handleReset = (projectselector) => {
    this.setState({
      idNo: 0
    });
    this.props.pswitch(projectselector);
  }

  render() {

    return (
    <Aux>
      <div className="project_image_extended">
      <div id="all_img_holder" className="all_img_holder">
      <h3 className="segment_title_project">
        <span id="parrow" onClick={(e) => this.handleReset(e.target.id)} className="previous_arrow"></span>
        {this.props.gallerylist[this.props.galleryElement].name}
        <span id="narrow" onClick={(e) => this.handleReset(e.target.id)} className="next_arrow"></span>
      </h3>
          {this.props.gallerylist[this.props.galleryElement].images.map((singleImage, index) => (
            <div key={index} className="img_hodler">
              <img id={index} key={index} src={singleImage} />
            </div>
          )
        )
      }
      </div>
        <div className="selectors_holder">
            {this.props.gallerylist[this.props.galleryElement].images.map((singleImage, index) => (
              <span onClick={(e) => this.handleImageChange(e.target.id)} id={index} key={index} className="selectors_holder_single_item js-dots"></span>
                )
              )
            }
        </div>
      </div>
      <div className="project_gallery_command_holder" >
        <span id="previous" onClick={(e) => this.handleImageChange(e.target.id)} className="previous_arrow" ></span>
        <span id="next" onClick={(e) => this.handleImageChange(e.target.id)} className="next_arrow" ></span>
      </div>
    </Aux>
  )
  }
}

export default SliderComponent;
