import React from 'react';
import logo from '../../images/logo.svg';
import upwork from '../../images/upwork_icon.svg';
import instagram from '../../images/instagramnew.svg';
import mail from '../../images/mail.svg';



const Footer = (props) => {

  // let navBurgerClasses = 'burger_icon';
  // let nav_container = 'nav_container';
  // let background = 'background';
  // let contact = 'contact_container';
  // let contactToggle= '';
  // if (props.navState) {
  //   navBurgerClasses = "burger_icon burger_icon_open";
  //   nav_container = "nav_container nav_container_open";
  //   background = "background background_open";
  //   contact = "contact_container";
  // }
  // if (props.contactState) {
  //   contact = "contact_container contact_container_open";
  //   contactToggle = "contact_active";
  // }

  return (
    <div id="footer_element" className="footer_container">
      <div className="footer_logo_copyright">
        <a href="#"><img src={logo} className="footer_logo" alt="UnderConstructionCity" /></a>
        <p className="copyright">Copyright © <b>CircleStudio</b> 2020.</p>
      </div>
      <div className="footer_contact_info">
        <a href="#"><img src={mail} className="footer_logo" alt="UnderConstructionCity" /></a>
        <a href="#"><img src={instagram} className="footer_logo" alt="UnderConstructionCity" /></a>
        <a href="#"><img src={upwork} className="footer_logo" alt="UnderConstructionCity" /></a>
      </div>
    </div>
  )
}

export default Footer;
