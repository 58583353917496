import React, { useState } from 'react';

const StockholmGallery = (props) => {
  const [selectedItem, setItem] = useState(true);
  var cityClasses = selectedItem ? "beachcity_wrapper shaded_overlay_after shaded_overlay small_wrapper telaviv_image_container" : "beachcity_wrapper shaded_overlay_after large_wrapper telaviv_image_container";
  var beachClasses = selectedItem ? "beachcity_wrapper shaded_overlay_after large_wrapper telaviv_image_container" : "beachcity_wrapper shaded_overlay_after shaded_overlay small_wrapper telaviv_image_container";

  return (
    <div>
      <h3 className="city_gallery_headline">Stockholm<span><i>59.3293° <b>N</b>, 34.7818° <b>E</b></i></span></h3>

      <div className="full_height_text_container">
        <h4>
        THE PALACE
        </h4>
        <p className="year_slider">1985.<span className="architect_name_slider"> - Name Middle Jr Lastname </span></p>
        <p className="info_text_slider">
        The War of the 5 kings. A forked purple lightning bolt, on black field speckled with four-pointed stars. The rains of castamere. Words are like wind. Bastards are born of passion, aren't they? We don't despise them in Dorne.
        Created by Rich Finelli.
        </p>
      </div>

      <div className="full_height_text_container">
        <h4>
        MODERNA MUSEET
        </h4>
        <p className="year_slider">1995.<span className="architect_name_slider"> - Name Middle Jr Lastname </span></p>
        <p className="info_text_slider">
        The War of the 5 kings. A forked purple lightning bolt, on black field speckled with four-pointed stars. The rains of castamere. Words are like wind. Bastards are born of passion, aren't they? We don't despise them in Dorne.
        Created by Rich Finelli.
        </p>
      </div>

      <div className="full_height_text_container">
        <h4>
        GAMLA STAN
        </h4>
        <p className="year_slider">1795.<span className="architect_name_slider"> - Name Middle Jr Lastname </span></p>
        <p className="info_text_slider">
        The War of the 5 kings. A forked purple lightning bolt, on black field speckled with four-pointed stars. The rains of castamere. Words are like wind. Bastards are born of passion, aren't they? We don't despise them in Dorne.
        Created by Rich Finelli.
        </p>
      </div>

      <div className="full_height_text_container">
        <h4>
        NORA TORREN
        </h4>
        <p className="year_slider">2016.<span className="architect_name_slider"> - Name Middle Jr Lastname </span></p>
        <p className="info_text_slider">
        The War of the 5 kings. A forked purple lightning bolt, on black field speckled with four-pointed stars. The rains of castamere. Words are like wind. Bastards are born of passion, aren't they? We don't despise them in Dorne.
        Created by Rich Finelli.
        </p>
      </div>
    </div>
  )
}

export default StockholmGallery;
