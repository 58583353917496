import React, { useState } from 'react';
import BelgradeGallery from './BelgradeGallery/BelgradeGallery';
import TelAvivGallery from './TelAvivGallery/TelAvivGallery';
import StockholmGallery from './Stockholm/Stockholm';

const Gallery = (props) => {

  var openGalleryHolder = props.galleryContainer ? "gallery_container gallery_container_open" : "gallery_container";
  const [city, setCount] = useState('');

  return (
    <div className="gallery_wrapper">
      <h3 className="segment_title">GALLERY</h3>
      <div onClick={props.galleryContainerOpen}>
        <div onClick={() => setCount('belgrade')} className="gallery_summary_wrapper">
          <div className="headline_and_coordinates">
            <h3>BELGRADE</h3>
            <p className="coordinates"><i>44.7866° <b>N</b>, 20.4489° <b>E</b></i></p>
          </div>
          <figure className="gallery_visual" >
            <img className="img_shadow_coa" src="http://www.underconstructioncity.com/imgs/BEG_coa_shadow.png" alt="belgradeCoatOfArmsshadow"/>
            <img className="img_coa" src="http://www.underconstructioncity.com/imgs/BEG_coa.png" alt="belgradeCoatOfArms" />
          </figure>
        </div>
        <div onClick={() => setCount('telaviv')} className="gallery_summary_wrapper">
          <div className="headline_and_coordinates">
            <h3>תל־אביב–יפו / TEL AVIV</h3>
            <p className="coordinates"><i>32.0853° <b>N</b>, 34.7818° <b>E</b></i></p>
          </div>
          <figure className="gallery_visual">
            <img className="img_shadow_coa" src="http://www.underconstructioncity.com/imgs/TLV_coa_shadow.png" alt="belgradeCoatOfArmsshadow" />
            <img className="img_coa" src="http://www.underconstructioncity.com/imgs/TLA_coa.png" alt="belgradeCoatOfArms" />
          </figure>
        </div>
        <div onClick={() => setCount('stgallen')} className="gallery_summary_wrapper soon_message">
          <div className="headline_and_coordinates">
            <h3>ST. GALLEN</h3>
            <p className="coordinates"><i>47.4245° <b>N</b>, 9.3767° <b>E</b></i></p>
          </div>
          <figure className="gallery_visual">
            <img className="img_shadow_coa img_fade shadow_fade" src="http://www.underconstructioncity.com/imgs/STGLN_coa_shadow.png" alt="belgradeCoatOfArmsshadow" />
            <img className="img_coa img_fade" src="http://www.underconstructioncity.com/imgs/STGLN_coa.png" alt="belgradeCoatOfArms" />
          </figure>
        </div>
        <div onClick={() => setCount('zagreb')} className="gallery_summary_wrapper soon_message">
          <div className="headline_and_coordinates">
            <h3>ZAGREB</h3>
            <p className="coordinates"><i>45.8150° <b>N</b>, 15.9819° <b>E</b></i></p>
          </div>
          <figure className="gallery_visual">
            <img className="img_shadow_coa img_fade shadow_fade" src="http://www.underconstructioncity.com/imgs/ZGB_coa_shadow.png" alt="belgradeCoatOfArmsshadow" />
            <img className="img_coa img_fade" src="http://www.underconstructioncity.com/imgs/ZGB_coa.png" alt="belgradeCoatOfArms" />
          </figure>
        </div>
        <div onClick={() => setCount('stockholm')} className="gallery_summary_wrapper soon_message">
          <div className="headline_and_coordinates">
            <h3>STOCKHOLM</h3>
            <p className="coordinates"><i>59.3293° <b>N</b>, 18.0686° <b>E</b></i></p>
          </div>
          <figure className="gallery_visual">
            <img className="img_shadow_coa img_fade shadow_fade" src="http://www.underconstructioncity.com/imgs/STH_coa_shadow.png" alt="belgradeCoatOfArmsshadow" />
            <img className="img_coa img_fade" src="http://www.underconstructioncity.com/imgs/STH_coa.png" alt="belgradeCoatOfArms" />
          </figure>
        </div>
        <div onClick={() => setCount('malta')} className="gallery_summary_wrapper soon_message">
          <div className="headline_and_coordinates">
            <h3>MALTA</h3>
            <p className="coordinates"><i>35.9375° <b>N</b>, 14.3754° <b>E</b></i></p>
          </div>
          <figure className="gallery_visual">
            <img className="img_shadow_coa img_fade shadow_fade" src="http://www.underconstructioncity.com/imgs/MLT_coa_shadow.png" alt="belgradeCoatOfArmsshadow" />
            <img className="img_coa img_fade" src="http://www.underconstructioncity.com/imgs/MLT_coa.png" alt="belgradeCoatOfArms" />
          </figure>
        </div>
        <div onClick={() => setCount('paris')} className="gallery_summary_wrapper soon_message">
          <div className="headline_and_coordinates">
            <h3>PARIS</h3>
            <p className="coordinates"><i>48.8566° <b>N</b>, 2.3522° <b>E</b></i></p>
          </div>
          <figure className="gallery_visual">
            <img className="img_shadow_coa img_fade shadow_fade" src="http://www.underconstructioncity.com/imgs/PAR_coa_shadow.png" alt="belgradeCoatOfArmsshadow" />
            <img className="img_coa img_fade" src="http://www.underconstructioncity.com/imgs/PAR_coa.png" alt="belgradeCoatOfArms" />
          </figure>
        </div>
      </div>


      <div className={openGalleryHolder}>

        { city == 'belgrade' ? (
          <BelgradeGallery/>
        ) : city == 'stockholm' ? (
          <StockholmGallery/>
        ) : city == 'telaviv' ?  (
          <TelAvivGallery/>
        ) : (
        <p>still in the making please don't hold your breath. Visit some of our other amazing content.</p>
        )}

        <div className="closeGallery" onClick={props.galleryContainerOpen}></div>

      </div>



    </div>
  )
}

export default Gallery;
