import React, { useState } from 'react';

const TelAvivGallery = (props) => {
  const [selectedItem, setItem] = useState(true);
  var cityClasses = selectedItem ? "beachcity_wrapper shaded_overlay_after shaded_overlay small_wrapper telaviv_image_container" : "beachcity_wrapper shaded_overlay_after large_wrapper telaviv_image_container";
  var beachClasses = selectedItem ? "beachcity_wrapper shaded_overlay_after large_wrapper telaviv_image_container" : "beachcity_wrapper shaded_overlay_after shaded_overlay small_wrapper telaviv_image_container";

  return (
    <div>
      <h3 className="city_gallery_headline">תל־אביב–יפו / TEL AVIV<span><i>32.0853° <b>N</b>, 34.7818° <b>E</b></i></span></h3>

      <div className="telaviv_slider_wrapper">
        <div onMouseEnter={() => setItem(false)} className={cityClasses}>
        <p className="beach-p">THE BEACH</p>
          <div className="gallery_photo_container telavivbeach"></div>
          <div className="gallery_photo_container telavivbeach2"></div>
          <div className="gallery_photo_container telavivbeach3"></div>
        </div>

        <div onMouseEnter={() => setItem(true)} className={beachClasses}>
        <p className="city-p">THE CITY</p>
          <div className="gallery_photo_container telavivcity"></div>
          <div className="gallery_photo_container telavivcity2"></div>
          <div className="gallery_photo_container telavivcity3"></div>
        </div>
      </div>
    </div>
  )
}

export default TelAvivGallery;
