import React from 'react';
import SliderComponent from '../SliderComparison';

const BelgradeGallery = (props) => {

  return (
    <div>
      <h3 className="city_gallery_headline">Belgrade<span><i>44.7866° <b>N</b>, 20.4489° <b>E</b></i></span></h3>

      <div className="inner_gallery">
        <h4 className="headline_float_right">ONLY FOR A WHILE</h4>
        <p className="description_small">
          During 2018/2019 Belgrade city manager decided to cut down planetrees along couple of main streets. Under an exuse that it was done because of the streets reconstruction, some of the greenest streets became the grayest. Instead of mourning, while the nature does its work, we decided to take the opportunity and take photographs of amazing facades that are now fully visible only for a short period of time. Lift your head up and enjoy architecture while we wait for the greenery and shade. <span></span>
        </p>
        <figure className="full_width_img_gallery">
          <img src="http://underconstructioncity.com/imgs/Niz_v2-min.jpg" alt="cara_dusana_vizual"/>
          <figcaption>
            Cara Dušana Street front, between Skadarska Street and Francuska street <i>UCC © 2019.</i>
        </figcaption>
        </figure>
          <figure className="full_width_img_gallery">
            <img src="http://underconstructioncity.com/imgs/Niz_v1-min.jpg" alt="cara_dusana_vizual"/>
            <figcaption>
              Džordža Vašingtona Street front, between Despota Stefana Boulevard and Cetinjska street <i>UCC © 2019.</i>
            </figcaption>
        </figure>

        <div className="segment_divider_gallery"></div>

        <h4 className="headline_float_right">VIEW OVERVIEW</h4>
        <p className="description_small">
          Lokking out from above, this is what you can see. Different viewpoints, different heights, same city. General overview of what Belgrade looks like.
        </p>
        <div className="background_images_container">
          <div className="feed_one"></div>
          <div className="feed_multiple_holder">
            <div className="vertical_flex gallery_w1">
              <div className="gallery_v1"></div>
              <div className="gallery_v2"></div>
            </div>
            <div className="vertical_flex gallery_w2">
              <div className="gallery_v3"></div>
              <div className="gallery_v4"></div>
            </div>
          </div>
        </div>

        <div className="segment_divider_gallery"></div>
        <SliderComponent />


        <div className="segment_divider_gallery"></div>
        <h4 className="headline_float_right">CUT OUTs</h4>
        <p className="description_small">
          We made a collage out of buildings cut-outs, and used them to make an alternative Belgrade skyline. In the future we will update the platform so you, the users, can manipulate and create your own cityscape. Enjoy a single variation in alternate Belgrade while we update our libraries. <span></span>
        </p>
        <figure className="full_width_img_gallery">
          <img src="http://underconstructioncity.com/imgs/panorama_maketa.jpg" alt="cara_dusana_vizual"/>
          <figcaption>
              Have you been to this city?  <i>UCC © 2019.</i>
          </figcaption>
        </figure>
      </div>
    </div>
  )
}

export default BelgradeGallery;
